import store from '@/store'

export default {
    state: {
        stokGrupListesi: [],
        stokListesi: [],
    },
    getters: {
        STOK_LIST_GET(state) {
            return state.stokListesi ? state.stokListesi : false
        },
        STOK_GROUP_GET(state) {
            return state.stokGrupListesi ? state.stokGrupListesi : false
        },
    },
    mutations: {
        STOK_LIST_ACTION(state, action) {
            // eslint-disable-next-line no-param-reassign
            delete action.userToken
            // eslint-disable-next-line no-unused-expressions
            action !== 'remove' ? state.stokListesi = action : state.stokListesi = []
        },
        STOK_GROUP_ACTION(state, action) {
            // eslint-disable-next-line no-unused-expressions
            action !== 'remove' ? state.stokGrupListesi = action : state.stokGrupListesi = []
        },
    },
    actions: {
        stokDelete({ commit },
                   authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'stok',
                methodName: 'stokSil',
                stokID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        // commit('STOK_LIST_ACTION', res.data)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        stokSave({
                     state,
                     commit,
                 }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'stok',
                methodName: 'stokKayit',
                stokAdi: authData.stokAdi || '',
                stokGrupID: authData.stokGrupID || '',
                adet: authData.adet || 0,
                birim: authData.birim || 'Adet',
                birimFiyat: authData.birimFiyat || 0,
                kdv: authData.kdv || 0,
                alisFiyat: authData.alisFiyat || 0,
                stoksuzSatis: authData.stoksuzSatis == true ? 1 : 0,
                barkod: authData.barkod || '',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        state.stokListesi.push(res.data)
                        // commit('STOK_LIST_ACTION', res.data)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        stokList({
                     commit,
                 }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'stok',
                methodName: 'stokListesiSearch',
                searchKey: authData.searchKey || '',
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
                [authData.stokGrupID != 0 ? 'stokGrupID' : '']: authData.stokGrupID != 0 ? authData.stokGrupID : '',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('STOK_LIST_ACTION', res.kayitlar)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        stokListSwapGet({
                            commit,
                        }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'stok',
                methodName: 'stokListesiSearch',
                searchKey: authData.searchKey || '',
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
                [authData ? 'stokGrupID' : '']: authData || '',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('STOK_LIST_ACTION', res.kayitlar)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        stokGroupRemove({
                            commit,
                        }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'stok',
                methodName: 'stokGrupSil',
                stokGrupID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.data.length > 0) {
                            commit('STOK_GROUP_ACTION', res.data)
                        }
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        stokGroupSave({
                          commit,
                      }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'stok',
                methodName: authData.islem != 'edit' ? 'stokGrupKayit' : 'stokGrupDuzenle',
                grupTuru: 0,
                grupAdi: authData.adi || '',
                [authData.islem == 'edit' ? 'stokGrupID' : '']: authData.grupID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('STOK_GROUP_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        stokGrup({
                     commit,
                 }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'stok',
                methodName: 'stokGrupListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        delete res.userToken
                        commit('STOK_GROUP_ACTION', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        stokGroupSwap({
                          commit,
                      }, authData) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    processData: false,
                    contentType: false,
                    data: authData,
                    success(res) {
                        commit('STOK_GROUP_ACTION', res.cariGrupListesi)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
