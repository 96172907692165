export default [
    {
        path: '/depolar',
        name: 'depolar',
        component: () => import('@/views/depo/depoList.vue'),
        meta: {
            pageTitle: 'Depolar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Depolar',
                    second: 'Depo Listesi',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/depoDetay/:id',
        name: 'depoDetay',
        component: () => import('@/views/depo/depoDetay.vue'),
        meta: {
            pageTitle: 'Depolar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Depolar',
                    second: 'Depo Listesi',
                    secondLink: 'depolar',
                    third: 'Depo Detay',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/aktarimDetay/:id',
        name: 'aktarimDetay',
        component: () => import('@/views/depo/aktarimDetay.vue'),
        meta: {
            pageTitle: 'Depolar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Depolar',
                    second: 'Depo Listesi',
                    secondLink: 'depolar',
                    third: 'Depo Detay',
                    fourth: 'Aktarım Detay',
                    active: true,
                },
            ],
        },
    },
]
