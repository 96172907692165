import store from '@/store'

export default {
    state: {
        giderGrupListesi: [],
        giderListesi: [],
        cariDetay: [],
        cariEkstre: [],
        hesapOzeti: [],
        islemGecmisi: [],
        faturalar: [],
        acikFaturalar: [],
        tahsilatVeOdemeKayitlari: [],
    },
    getters: {
        GIDER_GRUP_NAME_GET: state => id => {
            if (state.giderGrupListesi) {
                const grupAdi = Object.values(state.giderGrupListesi)
                    .find(x => x.giderGrupID === id)?.grupAdi
                return grupAdi || ''
            }
            return false
        },
        GIDER_LISTESI(state) {
            return state.giderListesi ? state.giderListesi : false
        },
        GIDER_GRUP_LISTESI(state) {
            return state.giderGrupListesi ? state.giderGrupListesi : false
        },
    },
    mutations: {
        GIDER_GRUP_ACTION(state, {
            payload,
            durum,
        }) {
            if (durum != 'remove') {
                const action = { ...payload }
                delete action.userToken
                state.giderGrupListesi = action
            } else {
                state.giderGrupListesi = []
            }
        },
        GIDER_LIST_ACTION(state, {
            payload,
            durum,
        }) {
            if (durum != 'remove') {
                const action = { ...payload }
                state.giderListesi = action
            } else {
                state.giderListesi = []
            }
        },
    },
    actions: {
        giderIslem({
                       commit,
                   }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'gider',
                methodName: 'giderKaydet',
                giderGrupID: authData.grupID,
                giderAdi: authData.aciklama,
                cikisTuru: authData.odemeTuru,
                tutar: authData.tutar.replace(/,/g, '.'),
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        giderRemove({
                        commit,
                    }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'gider',
                methodName: 'giderSil',
                giderID: authData.giderID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        giderListesi({
                         commit,
                     }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'gider',
                methodName: 'giderKayitlariSearch',
                searchKey: authData.searchKey || '',
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
                [authData.giderGrupID ? 'giderGrupID' : '']: authData.giderGrupID || '',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit({
                            type: 'GIDER_LIST_ACTION',
                            payload: res.liste,
                            durum: 'önemsiz',
                        })
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        giderGroupRemove({
                             commit,
                         }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'gider',
                methodName: 'giderGrupSil',
                giderGrupID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        giderGroupSave({ // todo gider grup save
                           commit,
                       }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'gider',
                methodName: authData.islem == 'kayit' ? 'giderGrupKaydet' : 'giderGrupDuzenle',
                islem: authData.islem,
                grupTuru: authData.tur,
                grupAdi: authData.adi,
                [authData.islem === 'edit' ? 'giderGrupID' : '']: authData.giderGrupID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        // todo kaydettikten sonra grup listesi tekrar gelmiyor"
                        // commit({
                        //     type: 'GIDER_GRUP_ACTION',
                        //     payload: res,
                        //     durum: 'save önemi yok', // todo remove haricinde ne giderse gitsin
                        // })
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        giderGrupListesi({
                             commit,
                         }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'gider',
                methodName: 'giderGruplari',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit({
                            type: 'GIDER_GRUP_ACTION',
                            payload: res,
                            durum: 'save önemi yok', // todo remove haricinde ne giderse gitsin
                        })
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
