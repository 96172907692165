export default [
    {
        path: '/satislar',
        name: 'satislar',
        component: () => import('@/views/fatura/satislar.vue'),
        meta: {
            pageTitle: 'Fatura',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Satışlar',
                    second: 'Satış Faturaları',
                    active: true,
                },
            ],
        },
    }, {
        path: '/alislar',
        name: 'alislar',
        component: () => import('@/views/fatura/alislar.vue'),
        meta: {
            // contentRenderer: 'sidebar-left',
            // contentRenderer: 'sidebar-left-detached',
            pageTitle: 'Fatura',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Giderler',
                    second: 'Alış Faturaları',
                    active: true,
                },
            ],
        },
    }, {
        path: '/yeniSatis',
        name: 'yeniSatis',
        component: () => import('@/views/fatura/yeniSatis.vue'),
        meta: {
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Satışlar',
                    second: 'Satış Faturaları',
                    secondLink: 'satislar',
                    third: 'Yeni Satış Faturası',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/yeniAlis',
        name: 'yeniAlis',
        component: () => import('@/views/fatura/yeniAlis.vue'),
        meta: {
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Giderler',
                    second: 'Alış Faturaları',
                    secondLink: 'alislar',
                    third: 'Yeni Alış Faturası',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/faturaDetay/:id',
        name: 'faturaDetay',
        component: () => import('@/views/fatura/faturaDetay.vue'),
        meta: {
            icon: 'StarIcon',
            breadcrumb: [
                {
                    faturaDetay: true,
                    first: 'Belli Olacak',
                    second: 'burasıda belli olacak',
                    third: 'Fatura Detay',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/proformalar',
        name: 'proformalar',
        component: () => import('@/views/fatura/proformalar.vue'),
        meta: {
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Satışlar',
                    second: 'Proforma Listesi',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/yeniProforma',
        name: 'yeniProforma',
        component: () => import('@/views/fatura/yeniProforma.vue'),
        meta: {
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Satışlar',
                    second: 'Proforma Listesi',
                    secondLink: 'proformalar',
                    third: 'Yeni Proforma',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/proformaDetay/:id',
        name: 'proformaDetay',
        component: () => import('@/views/fatura/proformaDetay.vue'),
        meta: {
            icon: 'StarIcon',
            subRoute: true,
            breadcrumb: [
                {
                    first: 'Satışlar',
                    second: 'Proforma Listesi',
                    secondLink: 'proformalar',
                    third: 'Proforma Detay',
                    text: 'Proforma Detay',
                    active: true,
                },
            ],
        },
    }, {
        path: '/proformaToFatura/:id',
        name: 'proformaToFatura',
        component: () => import('@/views/fatura/proformaToFatura.vue'),
        meta: {
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Satışlar',
                    second: 'Proforma Listesi',
                    secondLink: 'proformalar',
                    third: 'Yeni Satış Faturası',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/faturaDuzenle/:id',
        name: 'faturaDuzenle',
        component: () => import('@/views/fatura/faturaDuzenle.vue'),
        meta: {
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Satışlar',
                    second: 'Fatura Listesi',
                    secondLink: 'satislar',
                    third: 'Fatura Detay',
                    fourth: 'Fatura Düzenle',
                    active: true,
                },
            ],
        },
    },
]
