export default [
    {
        path: '/ihaleOlustur',
        name: 'ihaleOlustur',
        component: () => import('@/views/ihale/ihaleOlustur.vue'),
        meta: {
            pageTitle: 'İhaleler',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'İhaleler',
                    second: 'İhale Oluştur',
                    active: true,
                },
            ],
        },
    }, {
        path: '/komisyonUyeleri',
        name: 'komisyonUyeleri',
        component: () => import('@/views/ihale/komisyonUyeleri.vue'),
        meta: {
            pageTitle: 'İhaleler',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'İhaleler',
                    second: 'Komisyon Üyeleri',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/ihaleDetay/:id',
        name: 'ihaleDetay',
        component: () => import('@/views/ihale/ihaleDetay.vue'),
        meta: {
            pageTitle: 'İhaleler',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'İhaleler',
                    second: 'İhale Listesi',
                    secondLink: 'ihaleListesi',
                    third: 'İhale Detay',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/ihaleListesi',
        name: 'ihaleListesi',
        component: () => import('@/views/ihale/ihaleListesi.vue'),
        meta: {
            pageTitle: 'İhaleler',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'İhaleler',
                    second: 'İhale Listesi',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/firmaListesi',
        name: 'firmaListesi',
        component: () => import('@/views/ihale/firmaListesi.vue'),
        meta: {
            pageTitle: 'İhaleler',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'İhaleler',
                    second: 'Firma Listesi',
                    active: true,
                },
            ],
        },
    },
]
