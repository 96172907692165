<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view/>
    </component>
    <scroll-to-top v-if="enableScrollToTop"/>
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import router from '@/router'
import axios from 'axios'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // this.$store.dispatch('authControl')
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
          `--${colors[i]}`,
          document.documentElement,
      )
          .value
          .trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
          useCssVar(
              `--breakpoint-${breakpoints[i]}`,
              document.documentElement,
          )
              .value
              .slice(0, -2),
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const {
      skin,
      skinClasses,
    } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  created() {
    function findStatus(response) {
      if (typeof response === 'object') {
        for (var key in response) {
          if (key === 'tokenStatus') {
            if (response[key] == 503) {
              store.state.isAuth = false
              router.push({
                name: 'expariedLogin',
              })
            }
            return response
          }
          var status = findStatus(response[key])
          if (status !== undefined) {
            return response
          }
          return response
        }
      }
      return response
    }

    $(document)
        .on('ajaxComplete', function (event, xhr, settings) {
          var response = xhr.responseJSON
          findStatus(response)
        })
    axios.interceptors.response.use(
        (response) => {
          // var responseData = response.data
          // findStatus(responseData)
          return response
        },
        (error) => {
          if (error.response.status == 503) {
            store.state.isAuth = false
            router.push({
              name: 'expariedLogin',
            })
            return error
          }
          return error
        },
    )
    setTimeout(() => {
      const root = document.querySelector(':root')
      let myValue = getComputedStyle(root)
          .getPropertyValue('--project-box-shadow')
      root.style.setProperty('--project-box-shadow', store.getters.COLORS_GET.shadowColor)
      myValue = getComputedStyle(root)
          .getPropertyValue('--project-box-shadow')
    }, 1000)
  },
}
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

@media print {
  hr,
  .unPrint {
    display: none !important;
  }

  @page {
    margin: 5%;
  }

  button {
    display: none !important;
  }

  .card h4,
  .card h3 {
    white-space: nowrap !important;
  }
}

.d-grid {
  display: grid !important
}

.heading-elements {
  display: none !important;
}

.b-table tbody tr:hover td:first-child {
  border-left: 2px solid #609EA2;
}

.modern-nav-toggle {
  color: white;
  transition: 400ms;
}

.modern-nav-toggle:hover {
  color: darkorange;
  transition: 400ms;
}

.actions-card .card .card-header {
  display: none !important;
}

.card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
}

.list-card {
  width: 100% !important;
}

.list-card .card-header {
  display: none !important;
}

.card-title {
  transform: translateY(50%) !important;
}

.card-btn {
  transform: translateY(8%) !important;
  float: right;
}

</style>
