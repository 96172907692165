export default [
    {
        path: '/stokGrup',
        name: 'stokGrup',
        component: () => import('@/views/stok/stokGrup.vue'),
        meta: {
            pageTitle: 'Stok',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Stoklar',
                    second: 'Stok Grupları',
                    active: true,
                },
            ],
        },
    }, {
        path: '/stokListesi/:id',
        name: 'stokListesi',
        component: () => import('@/views/stok/stokListe.vue'),
        meta: {
            pageTitle: 'Stoklar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Stoklar',
                    second: 'Stok Listesi',
                    active: true,
                },
            ],
        },
    },
]
