export default [
    {
        path: '/banka',
        name: 'banka',
        component: () => import('@/views/banka/banka.vue'),
        meta: {
            pageTitle: 'Banka Havaleleri',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Kasa ve Banka',
                    second: 'Banka Havaleleri',
                    active: true,
                },
            ],
        },
    },
]
