export default [
    {
        path: '/kasa',
        name: 'kasa',
        component: () => import('@/views/kasa/kasaOzeti.vue'),
        meta: {
            pageTitle: 'Kasa',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Kasa ve Banka',
                    second: 'Kasa Özeti',
                    active: true,
                },
            ],
        },
    },
]
