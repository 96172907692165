import store from '@/store'

export default {
    state: {
        rHesapOzeti: [],
        rKasa: [],
        rAlisFaturalari: [],
        rSatisFaturalari: [],
        rGiderler: [],
    },
    getters: {
        R_HESAP_OZETI_GET(state) {
            if (state.rHesapOzeti) {
                return state.rHesapOzeti
            }
            return false
        },
        R_KASA_GET(state) {
            if (state.rKasa) {
                return state.rKasa
            }
            return false
        },
        R_ALIS_FATURALARI(state) {
            if (state.rAlisFaturalari) {
                return state.rAlisFaturalari
            }
            return false
        },
        R_SATIS_FATURALARI(state) {
            if (state.rSatisFaturalari) {
                return state.rSatisFaturalari
            }
            return false
        },
        R_GIDERLER(state) {
            if (state.rGiderler) {
                return state.rGiderler
            }
            return false
        },
    },
    mutations: {
        R_HESAP_OZETI_ACTION(state, payload) {
            payload !== 'remove' ? state.rHesapOzeti = { ...payload } : state.rHesapOzeti = []
        },
        R_KASA_ACTION(state, payload) {
            payload !== 'remove' ? state.rKasa = { ...payload } : state.rKasa = []
        },
        R_ALIS_FATURALARI_ACTION(state, payload) {
            payload !== 'remove' ? state.rAlisFaturalari = { ...payload } : state.rAlisFaturalari = []
        },
        R_SATIS_FATURALARI_ACTION(state, payload) {
            payload !== 'remove' ? state.rSatisFaturalari = { ...payload } : state.rSatisFaturalari = []
        },
        R_GIDERLER_ACTION(state, payload) {
            payload !== 'remove' ? state.rGiderler = { ...payload } : state.rGiderler = []
        },
    },
    actions: {
        giderlerRapor({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'rapor',
                methodName: 'giderKayitlari',
                start: authData.start,
                end: authData.end,
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('R_GIDERLER_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        satisFaturalariRapor({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'rapor',
                methodName: 'satisFaturalari',
                start: authData.start,
                end: authData.end,
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('R_SATIS_FATURALARI_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        alisFaturalariRapor({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'rapor',
                methodName: 'alisFaturalari',
                start: authData.start,
                end: authData.end,
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('R_ALIS_FATURALARI_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kasaRaporu({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'rapor',
                methodName: 'kasaRaporu',
                start: authData.start,
                end: authData.end,
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('R_KASA_ACTION', res.kasa)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        hesapOzetiRapor({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'rapor',
                methodName: 'hesapOzeti',
                start: authData.start,
                end: authData.end,
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('R_HESAP_OZETI_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
