import store from '@/store'

export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        etkinlikDelete({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'dashBoard',
                methodName: 'ajandaSil',
                ajandaID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        etkinlikSave({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'dashBoard',
                methodName: 'ajandaKaydet',
                baslik: authData.baslik,
                kategori: authData.kategori,
                baslangic: authData.baslangic,
                bitis: authData.bitis,
                kayitNotu: authData.not,
                tumGun: 0,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        etkinlikUpdate({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'dashBoard',
                methodName: 'ajandaGuncelle',
                ajandaID: authData.ajandaID,
                baslik: authData.baslik,
                kategori: authData.kategori,
                baslangic: authData.baslangic,
                bitis: authData.bitis,
                kayitNotu: authData.not,
                tumGun: 0,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        etkinlikTarihUpdate({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'dashBoard',
                methodName: 'ajandaTarihGuncelle',
                ajandaID: authData.ajandaID,
                baslangic: authData.baslangic,
                bitis: authData.bitis,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
