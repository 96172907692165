import store from '@/store'

export default {
    state: {
        salonlar: [],
        salonTurleri: [],
        rezervasyonList: [],
        rezervasyonDetay: [],
        ozetGorunum: [],
        opsiyonList: [],
    },
    getters: {
        OPSIYON_LIST(state) {
            return state.opsiyonList
        },
        OZET_GORUNUM_GET(state) {
            return state.ozetGorunum
        },
        SALON_LISTESI_GET(state) {
            return state.salonlar
        },
        SALON_TURU_LISTESI_GET(state) {
            return state.salonTurleri
        },
        REZERVASYON_LIST_GET(state) {
            return state.rezervasyonList
        },
        REZERVASYON_DETAY_GET(state) {
            return {
                bilgiler: state.rezervasyonDetay.rezervasyonBilgileri,
                finans: state.rezervasyonDetay.tahsilatlar,
                opsiyonlar: state.rezervasyonDetay.opsiyonBilgileri,
            }
        },
    },
    mutations: {
        OPSIYON_LIST_ACTION(state, payload) {
            payload !== 'remove' ? state.opsiyonList = { ...payload } : state.opsiyonList = []
        },
        OZET_GORUNUM_ACTION(state, payload) {
            payload !== 'remove' ? state.ozetGorunum = { ...payload } : state.ozetGorunum = []
        },
        SALON_LISTESI_ACTION(state, payload) {
            payload !== 'remove' ? state.salonlar = { ...payload } : state.salonlar = []
        },
        SALON_TURLERI_ACTION(state, payload) {
            payload !== 'remove' ? state.salonTurleri = { ...payload } : state.salonTurleri = []
        },
        REZERVASYON_LIST_ACTION(state, payload) {
            payload !== 'remove' ? state.rezervasyonList = { ...payload } : state.rezervasyonList = []
        },
        REZERVASYON_DETAY_ACTION(state, payload) {
            payload !== 'remove' ? state.rezervasyonDetay = { ...payload } : state.rezervasyonDetay = []
        },
    },
    actions: {
        ozetGorunumList({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'rezervasyonListesiSearch',
                baslangic: authData.baslangic || 0,
                searchKey: authData.searchKey,
                salonID: authData.salonID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('OZET_GORUNUM_ACTION', data.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        opsiyonListesi({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonOpsiyonListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('OPSIYON_LIST_ACTION', data.list)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        salonOpsiyonRemove({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonOpsiyonSil',
                opsiyonID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('OPSIYON_LIST_ACTION', data.list)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        salonOpsiyonSave({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonOpsiyonKaydet',
                opsiyonAdi: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('OPSIYON_LIST_ACTION', data.list)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        rezervasyonTahsilatRemove({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonTahsilatSil',
                rezervasyonID: authData.rezID,
                tahsilatID: authData.tahID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('REZERVASYON_DETAY_ACTION', data.data)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        rezervasyonDetay({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonRezervasyonDetay',
                rezervasyonID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('REZERVASYON_DETAY_ACTION', data.data)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        opsiyonRemove({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'rezervasyonOpsiyonSil',
                rezervasyonID: authData.rezID,
                atamaID: authData.atamaID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('REZERVASYON_DETAY_ACTION', data.data)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        rezervasyonRemove({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonRezervasyonSil',
                rezervasyonID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        rezervasyonListesi({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'rezervasyonListesiSearch',
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
                searchKey: authData.searchKey,
                [authData.salonID ? 'salonID' : '']: authData.salonID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('REZERVASYON_LIST_ACTION', data.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        salonListesi({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('SALON_LISTESI_ACTION', data)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        salonTuruListesi({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonTuruListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('SALON_TURLERI_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        salonKaydet({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonKaydet',
                salonAdi: authData.salonAdi,
                salonTurID: authData.salonTurID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        salonTurKayit({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonTuruKaydet',
                turAdi: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        salonTurRemove({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonTuruSil',
                salonTurID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        salonRemove({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonSil',
                salonID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        salonTurUpdate({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonTuruDuzenle',
                turAdi: authData.turAdi,
                salonTurID: authData.salonTurID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        salonUpdate({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'salon',
                methodName: 'salonDuzenle',
                salonID: authData.salonID,
                salonTurID: authData.salonTurID,
                salonAdi: authData.salonAdi,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
