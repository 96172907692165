export default [
    {
        path: '/rapor',
        name: 'rapor',
        component: () => import('@/views/rapor/raporOlustur.vue'),
        meta: {
            pageTitle: 'Rapor Oluştur',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Rapor Oluştur',
                    active: true,
                },
            ],
        },
    },
]
