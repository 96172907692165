export default [
    {
        path: '/',
        name: 'dashBoard',
        component: () => import('@/views/buro/dashBoard.vue'),
        meta: {
            pageTitle: 'DashBoard',
            breadcrumb: [
                {
                    first: 'Büro Dashboard',
                    dash: true,
                },
            ],
        },
    }, {
        path: '/login',
        name: 'login',
        component: () => import('@/views/buro/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    }, {
        path: '/expariedLogin',
        name: 'expariedLogin',
        component: () => import('@/views/buro/expariedToken.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
]
