import store from '@/store'

export default {
    state: {
        kasa: [],
        kasaOzetiListesi: [],
        kasaGelirListesi: [],
        kasaGiderListesi: [],
    },
    getters: {
        KASA_GET(state) {
            if (state.kasa) {
                return state.kasa
            }
            return false
        },
        KASA_GELIR_GET(state) {
            if (state.kasaGelirListesi) {
                return state.kasaGelirListesi
            }
            return false
        },
        KASA_GIDER_GET(state) {
            if (state.kasaGiderListesi) {
                return state.kasaGiderListesi
            }
            return false
        },
        KASA_OZETI_LISTESI_GET(state) {
            if (state.kasaOzetiListesi) {
                return state.kasaOzetiListesi
            }
            return false
        },
    },
    mutations: {
        KASA_ACTION(state, payload) {
            payload != 'remove' ? state.kasa = { ...payload } : state.kasa = []
        },
        KASA_GELIR_ACTION(state, payload) {
            payload != 'remove' ? state.kasaGelirListesi = { ...payload } : state.kasaGelirListesi = []
        },
        KASA_GIDER_ACTION(state, payload) {
            payload != 'remove' ? state.kasaGiderListesi = { ...payload } : state.kasaGiderListesi = []
        },
        KASA_OZETI_LISTESI_ACTION(state, payload) {
            payload != 'remove' ? state.kasaOzetiListesi = { ...payload } : state.kasaOzetiListesi = []
        },
    },
    actions: {
        kasaListesi({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'kasa',
                methodName: 'kasaHareketleriSearch',
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
                [authData.start ? 'start' : '']: authData.start,
                [authData.end ? 'end' : '']: authData.end,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('KASA_OZETI_LISTESI_ACTION', res.data)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        yillikRapor({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'kasa',
                methodName: 'aylikKasaHareketi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kasaGirisi({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'kasa',
                methodName: 'kasaGirisi',
                hareket: authData.girisTuru,
                aciklama: authData.not,
                tutar: authData.tutar.replace(/,/g, '.'),
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kasaSil({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'kasa',
                methodName: 'kasaSil',
                kasaID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kasaGiderListesi({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'kasa',
                methodName: 'kasaHareketleriSearch',
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
                hareket: 2,
                [authData.start ? 'start' : '']: authData.start,
                [authData.end ? 'end' : '']: authData.end,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('KASA_GIDER_ACTION', res.data)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kasaGelirListesi({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'kasa',
                methodName: 'kasaHareketleriSearch',
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
                hareket: 1,
                [authData.start ? 'start' : '']: authData.start,
                [authData.end ? 'end' : '']: authData.end,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('KASA_GELIR_ACTION', res.data)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kasaOzeti({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'kasa',
                methodName: 'kasaOzeti',
                [authData.start ? 'start' : '']: authData.start,
                [authData.end ? 'end' : '']: authData.end,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('KASA_ACTION', data)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
