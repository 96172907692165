export default [
    {
        path: '/salonListesi',
        name: 'salonListesi',
        component: () => import('@/views/salon/salonListesi.vue'),
        meta: {
            pageTitle: 'Salon Yönetimi',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Salon Yönetimi',
                    second: 'Salon Listesi',
                    secondLink: 'salonListesi',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/rezervasyonListesi',
        name: 'rezervasyonListesi',
        component: () => import('@/views/salon/rezervasyonList.vue'),
        meta: {
            pageTitle: 'Salonlar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Salon Yönetimi',
                    second: 'Rezervasyon Listesi',
                    secondLink: 'salonListesi',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/ozetGorunum',
        name: 'ozetGorunum',
        component: () => import('@/views/salon/ozetGorunum.vue'),
        meta: {
            pageTitle: 'Salonlar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Salon Yönetimi',
                    second: 'Özet Görünüm',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/rezervasyonDetay/:id',
        name: 'rezervasyonDetay',
        component: () => import('@/views/salon/rezervasyonDetay.vue'),
        meta: {
            pageTitle: 'Salonlar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Salon Yönetimi',
                    second: 'Rezervasyon Listesi',
                    secondLink: 'rezervasyonListesi',
                    third: 'Rezervasyon Detay',
                    active: true,
                },
            ],
        },
    },
]
