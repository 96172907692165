import store from '@/store'

export default {
    state: {
        cariGrupListesi: [],
        cariListesiForGroup: [],
        cariListesi: [],
        cariDetay: [],
        cariEkstre: [],
        hesapOzeti: [],
        islemGecmisi: [],
        faturalar: [],
        acikFaturalar: [],
        tahsilatVeOdemeKayitlari: [],
    },
    getters: {
        TAHSILAT_VE_ODEME_KAYITLARI(state) {
            return state.tahsilatVeOdemeKayitlari ? state.tahsilatVeOdemeKayitlari : false
        },
        ACIK_FATURALAR_GET(state) {
            return state.acikFaturalar ? state.acikFaturalar : false
        },
        FATURALAR_GET(state) {
            return state.faturalar ? state.faturalar : false
        },
        ISLEM_GECMISI_GET(state) {
            return state.islemGecmisi ? state.islemGecmisi : false
        },
        HESAP_OZETI_GET(state) {
            return state.hesapOzeti ? state.hesapOzeti : false
        },
        CARI_EKSTRE(state) {
            if (state.cariEkstre) {
                return {
                    ekstre: state.cariEkstre.ekstre,
                    cariBilgi: state.cariEkstre.cariBilgi,
                }
            }
            return false
        },
        GRUP_NAME_GET: state => id => {
            if (state.cariGrupListesi) {
                const grupAdi = Object.values(state.cariGrupListesi)
                    .find(x => x.cariGrupID === id)?.grupAdi
                return grupAdi || 'veri bulunamadı'
            }
            return false
        },
        CARI_DETAY_GET(state) {
            if (state.cariDetay.data) {
                return {
                    cariData: state.cariDetay.data.cariData,
                    cariEkstre: state.cariDetay.data.cariEkstre,
                    cariOzet: state.cariDetay.data.cariOzet,
                    hesapOzeti: state.cariDetay.data.cariEkstre,
                }
            }
            return false
        },
        CARI_CARD_GET(state) {
            return state.cariListesi ? state.cariListesi : false
        },
        CARI_LIST_GET(state) {
            return state.cariListesiForGroup ? state.cariListesiForGroup : false
        },
        CARI_GROUP_GET(state) {
            return state.cariGrupListesi ? state.cariGrupListesi : false
        },
    },
    mutations: {
        TAHSILAT_VE_ODEME_KAYITLARI_ACTION(state, action) {
            // eslint-disable-next-line no-unused-expressions
            action !== 'remove' ? state.tahsilatVeOdemeKayitlari = action : state.tahsilatVeOdemeKayitlari = []
        },
        ACIK_FATURALAR_ACTION(state, action) {
            // eslint-disable-next-line no-unused-expressions
            action !== 'remove' ? state.acikFaturalar = action : state.acikFaturalar = []
        },
        FATURALAR_ACTION(state, action) {
            // eslint-disable-next-line no-unused-expressions
            action !== 'remove' ? state.faturalar = action : state.faturalar = []
        },
        ISLEM_GECMISI_ACTION(state, action) {
            // eslint-disable-next-line no-unused-expressions
            action !== 'remove' ? state.islemGecmisi = action : state.islemGecmisi = []
        },
        HESAP_OZETI_ACTION(state, action) {
            // eslint-disable-next-line no-unused-expressions
            action !== 'remove' ? state.hesapOzeti = action : state.hesapOzeti = []
        },
        CARI_EKSTRE(state, action) {
            // eslint-disable-next-line no-param-reassign
            delete action.userToken
            // eslint-disable-next-line no-unused-expressions
            action !== 'remove' ? state.cariEkstre = action : state.cariEkstre = []
        },
        CARI_DETAY_ACTION(state, action) {
            // eslint-disable-next-line no-unused-expressions
            action !== 'remove' ? state.cariDetay = action : state.cariDetay = []
        },
        CARI_CARD_ACTION(state, action) {
            // eslint-disable-next-line no-unused-expressions
            action !== 'remove' ? state.cariListesi = action : state.cariListesi = []
        },
        CARI_LIST_ACTION(state, action) {
            // eslint-disable-next-line no-param-reassign
            delete action.userToken
            // eslint-disable-next-line no-unused-expressions
            action !== 'remove' ? state.cariListesiForGroup = action : state.cariListesiForGroup = []
        },
        CARI_GROUP_ACTION(state, action) {
            // eslint-disable-next-line no-unused-expressions
            action !== 'remove' ? state.cariGrupListesi = action : state.cariGrupListesi = []
        },
    },
    actions: {
        tahsilatlar({
                        commit,
                    }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'hesapOzetiSearch',
                cariKartID: authData.cariKartID,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 10,
                searchKey: authData.searchKey,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('TAHSILAT_VE_ODEME_KAYITLARI_ACTION', res.data.hesapOzeti)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariDetayFaturalar({
                               commit,
                           }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'faturaKayitlariSearch',
                cariKartID: authData.cariKartID,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 10,
                searchKey: authData.searchKey,
                [authData.acik ? 'acikFatura' : '']: authData.acik,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        if (!authData.acik) {
                            commit('FATURALAR_ACTION', res.data)
                        } else {
                            commit('ACIK_FATURALAR_ACTION', res.data)
                        }
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariDetayIslemGecmisi({
                                  commit,
                              }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariEkstreSearch',
                cariKartID: authData.cariKartID,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 10,
                searchKey: authData.searchKey,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('HESAP_OZETI_ACTION', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        devirKayit({
                       commit,
                   }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariDetay',
                islem: 'devirKaydi',
                cariKartID: authData.id,
                hareket: authData.girisTuru,
                tutar: authData.tutar,
                islemNotu: authData.aciklama,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('CARI_DETAY_ACTION', res)
                        commit('setToken', res.userToken)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kasaKayit({
                      commit,
                  }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariDetay',
                islem: 'kasaKayit',
                yontem: authData.yontem,
                cariKartID: authData.id,
                girisTuru: authData.girisTuru,
                tutar: authData.tutar,
                aciklama: authData.aciklama,
                fisNo: authData.fisNo,
                searchKey: authData.searchKey,
                baslangic: authData.baslangic,
                uzunluk: authData.uzunluk,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('CARI_DETAY_ACTION', res)
                        commit('HESAP_OZETI_ACTION', res.data?.cariEkstre)
                        commit('setToken', res.userToken)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariDetay({
                      commit,
                  }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariDetay',
                cariKartID: authData.cariKartID,
                baslangic: 0,
                uzunluk: 20,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('CARI_DETAY_ACTION', res)
                        commit('HESAP_OZETI_ACTION', res.data?.cariEkstre)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariEkstre({
                       commit,
                   }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariEkstre',
                cariKartID: authData.cariKartID,
                [authData.baslangic ? 'baslangic' : '']: authData.baslangic,
                [authData.bitis ? 'bitis' : '']: authData.bitis,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('CARI_EKSTRE', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        deleteTahsilat({
                           commit,
                       }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariDetay',
                islem: 'kasaSil',
                kasaID: authData.kasaID,
                cariKartID: authData.cariKartID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        if (res.status == 200) {
                            commit('CARI_DETAY_ACTION', res.liste)
                            commit('setToken', res.userToken)
                        }
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariCardRemove({
                           commit,
                       }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariSil',
                cariKartID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        if (res.status == 200) {
                            commit('CARI_CARD_ACTION', res.liste)
                            commit('setToken', res.userToken)
                        }
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariStatusChange({
                             commit,
                         }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariDetay',
                islem: 'sil',
                cariKartID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('CARI_CARD_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariCardUpdate({
                           commit,
                       }, authData) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data: authData,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('CARI_CARD_ACTION', res.data)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariCardSave({
                         commit,
                     }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'yeniCariKart',
                islem: 'yeniCariKart',
                vergiNumarasi: authData.vergiNumarasi,
                adi: authData.adi,
                soyAdi: authData.soyAdi,
                adres: authData.adres,
                ePosta: authData.ePosta,
                telefon: authData.telefon,
                firmaUnvan: authData.faturaUnvan,
                vergiDairesi: authData.vergiDairesi,
                bakiye: authData.bakiye,
                cariNot: authData.cariNot,
                il: authData.il,
                ilce: authData.ilce,
                banka: authData.banka,
                ibann: authData.ibann,
                cariGrupID: authData.cariGrupID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('CARI_LIST_ACTION', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariCardlar({
                        commit,
                    }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariListeSearch',
                [authData.searchKey ? 'searchKey' : '']: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
                [authData.cariGrupID != 0 ? 'cariGrupID' : '']: authData.cariGrupID != 0 ? authData.cariGrupID : '',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('CARI_CARD_ACTION', res.liste)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariGroupSwap({
                          commit,
                      }, authData) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    processData: false,
                    contentType: false,
                    data: authData,
                    success(res) {
                        commit('CARI_GROUP_ACTION', res.cariGrupListesi)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariList({
                     commit,
                 }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariListe',
                [authData ? 'cariGrupID' : '']: authData || '',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('CARI_LIST_ACTION', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariGroupRemove({
                            commit,
                        }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariGruplar',
                islem: 'sil',
                cariGrupID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('CARI_GROUP_ACTION', res.cariGrupListesi)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariGroupSave({
                          commit,
                      }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariGruplar',
                islem: authData.islem,
                tur: authData.tur,
                adi: authData.adi,
                aciklama: authData.aciklama,
                [authData.islem === 'edit' ? 'cariGrupID' : '']: authData.cariGrupID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('CARI_GROUP_ACTION', res.cariGrupListesi)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariGrup({
                     commit,
                 }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'cari',
                methodName: 'cariGruplar',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('CARI_GROUP_ACTION', res.cariGrupListesi)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
