export default [
    {
        path: '/cariGruplar',
        name: 'cariGruplar',
        component: () => import('@/views/cari/cariGrup.vue'),
        meta: {
            pageTitle: 'Cari Kartlar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Cari Kartlar',
                    second: 'Cari Gruplar',
                    active: true,
                },
            ],
        },
    }, {
        path: '/cariListe/:id',
        name: 'cariListe',
        component: () => import('@/views/cari/cariListe.vue'),
        meta: {
            pageTitle: 'Cari Kartlar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Cari Kartlar',
                    second: 'Cari Liste',
                    active: true,
                },
            ],
        },
    }, {
        path: '/cariDetay/:id',
        name: 'cariDetay',
        component: () => import('@/views/cari/cariDetay.vue'),
        meta: {
            pageTitle: 'Cari Kartlar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Cari Kartlar',
                    second: 'Cari Liste',
                    secondLink: 'cariListe',
                    third: 'Cari Detay',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cariEkstre/:id',
        name: 'cariEkstre',
        component: () => import('@/views/cari/cariEkstre.vue'),
        meta: {
            pageTitle: 'Cari Kartlar',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Cari Kartlar',
                    second: 'Cari Liste',
                    secondLink: 'cariListe',
                    third: 'Cari Detay',
                    fourth: 'Cari Ekstre',
                    active: true,
                },
            ],
        },
    },
]
