export default [
    {
        path: '/kullaniciListesi',
        name: 'kullaniciListesi',
        component: () => import('@/views/servis/userList.vue'),
        meta: {
            pageTitle: 'Kullanıcı Yönetimi',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Servis',
                    second: 'Kullanıcı Yönetimi',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/eFatura',
        name: 'eFatura',
        component: () => import('@/views/servis/eFatura.vue'),
        meta: {
            pageTitle: 'E-Fatura Ayarları',
            icon: 'StarIcon',
            breadcrumb: [
                {
                    first: 'Servis',
                    second: 'E-Fatura Ayarları',
                    active: true,
                },
            ],
        },
    },
]
