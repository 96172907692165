import store from '@/store'

export default {
    state: {
        cariToYeniSatisCariID: null,
        cariToYeniAlisCariID: null,
        cariToYeniProformaCariID: null,
        isProformaFatura: false,
        proformaToFatura: [],
        proformaListesi: {
            bekleyen: [],
            aktarilan: [],
        },
        proformaDetay: [],
        faturaDetay: [],
        vergiListesi: [],
        faturaListesi: {
            acikFatura: [],
            kapaliFatura: [],
        },
    },
    getters: {
        PROFORMA_TO_FATURA_GET(state) {
            return {
                bekleyen: state.proformaListesi.bekleyen,
                aktarilan: state.proformaListesi.aktarilan,
            }
        },
        PROFORMA_LIST_GET(state) {
            return {
                bekleyen: state.proformaListesi.bekleyen,
                aktarilan: state.proformaListesi.aktarilan,
            }
        },
        PROFORMA_DETAY_GET(state) {
            if (state.proformaDetay) {
                return {
                    faturaOzeti: state.proformaDetay.proformaOzeti || '',
                    faturaBilgileri: state.proformaDetay.proformaBilgileri || '',
                }
            }
            return false
        },
        FATURA_DETAY_GET(state) {
            if (state.faturaDetay) {
                return {
                    faturaOzeti: state.faturaDetay.faturaOzeti || '',
                    faturaBilgileri: state.faturaDetay.faturaBilgileri || '',
                    kasaKayitlari: state.faturaDetay.kasaKayitlari || '',
                }
            }
            return false
        },
        FATURA_LIST_GET(state) {
            return {
                acikFatura: state.faturaListesi.acikFatura,
                kapaliFatura: state.faturaListesi.kapaliFatura,
            }
        },
        VERGI_LISTESI_GET(state) {
            return state.vergiListesi ? state.vergiListesi : false
        },
    },
    mutations: {
        PROFORMA_TO_FATURA_ACTION(state, {
            payload,
            durum,
        }) {
            if (durum != 'remove') {
                const action = { ...payload }
                state.proformaToFatura = action.data
            } else {
                state.proformaToFatura = []
            }
        },
        PROFORMA_LIST_ACTION(state, {
            payload,
            durum,
        }) {
            if (durum != 'remove') {
                const action = { ...payload }
                durum == 0 ? state.proformaListesi.bekleyen = action.data : state.proformaListesi.aktarilan = action.data
            } else {
                state.proformaListesi.bekleyen = []
                state.proformaListesi.aktarilan = []
            }
        },
        PROFORMA_DETAY_ACTION(state, payload) {
            const action = payload !== 'remove' ? { ...payload } : []
            delete action.userToken
            state.proformaDetay = action
        },
        FATURA_DETAY_ACTION(state, payload) {
            const action = payload !== 'remove' ? { ...payload } : []
            delete action.userToken
            state.faturaDetay = action
        },
        FATURA_LIST_ACTION(state, {
            payload,
            stateTur,
        }) {
            if (stateTur != 'remove') {
                const action = { ...payload }
                stateTur == 'acik' ? state.faturaListesi.acikFatura = action.data : state.faturaListesi.kapaliFatura = action.data
            } else {
                state.faturaListesi.acikFatura = []
                state.faturaListesi.kapaliFatura = []
            }
        },
        VERGI_ACTION(state, payload) {
            const action = payload !== 'remove' ? { ...payload } : []
            delete action.userToken
            state.vergiListesi = action
        },
    },
    actions: {
        proformaListesi({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'fatura',
                methodName: 'proformaListesiSearch',
                faturaTuru: authData.faturaTuru || 1,
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
                durum: authData.durum || 0,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit({
                            type: 'PROFORMA_LIST_ACTION',
                            payload: res,
                            durum: authData.durum,
                        })
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        faturaDuzenle({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'fatura',
                methodName: 'faturaDetay',
                kayitID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit({
                            type: 'PROFORMA_TO_FATURA_ACTION',
                            payload: res,
                        })
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        proformaInfoToFatura({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'fatura',
                methodName: 'proformaDetay',
                proformaID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit({
                            type: 'PROFORMA_TO_FATURA_ACTION',
                            payload: res,
                        })
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        proformaDetay({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'fatura',
                methodName: 'proformaDetay',
                proformaID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('PROFORMA_DETAY_ACTION', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        eFaturaAktar({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'fatura',
                methodName: 'eFaturaAktar',
                kayitID: authData.kayitID,
                musteriEposta: authData.musteriEposta,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        faturaTahsilatRemove({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'fatura',
                methodName: 'faturaKasaSil',
                kayitID: authData.id,
                kasaID: authData.kasaID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('FATURA_DETAY_ACTION', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        faturaTahsilat({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'fatura',
                methodName: 'faturaKasaKayit',
                kayitID: authData.id,
                girisTuru: authData.girisTuru,
                tutar: authData.tutar,
                fisNo: authData.fisNo,
                aciklama: authData.aciklama,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('FATURA_DETAY_ACTION', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        proformaSil({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'fatura',
                methodName: 'proformaSil',
                proformaID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        faturaSil({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'fatura',
                methodName: 'faturaSil',
                kayitID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        faturaDetay({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'fatura',
                methodName: 'faturaDetay',
                kayitID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('FATURA_DETAY_ACTION', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        vergiListesi({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'fatura',
                methodName: 'vergiListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('VERGI_ACTION', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        faturaListesi({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'fatura',
                methodName: 'faturaListesiSearch',
                faturaTuru: authData.faturaTuru || 1,
                searchKey: authData.searchKey,
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 20,
                odemeDurum: authData.stateTur == 'acik' ? 0 : 1,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit({
                            type: 'FATURA_LIST_ACTION',
                            payload: res,
                            stateTur: authData.stateTur,
                        })
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
