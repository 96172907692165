import store from '@/store'

export default {
    state: {
        userList: [],
        eFatura: [],
    },
    getters: {
        EFATURA_GET(state) {
            if (state.eFatura) {
                return state.eFatura
            }
            return false
        },
        USER_LIST_GET(state) {
            if (state.userList) {
                return state.userList
            }
            return false
        },
    },
    mutations: {
        EFATURA_ACTION(state, payload) {
            const veri = { ...payload }
            payload != 'remove' ? state.eFatura = veri : state.eFatura = []
        },
        USER_LIST_ACTION(state, payload) {
            const veri = { ...payload }
            payload != 'remove' ? state.userList = veri : state.userList = []
        },
    },
    actions: {
        eFatura({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'dashBoard',
                methodName: 'buroAyar',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('EFATURA_ACTION', res.ayarlar)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        sifreDegisRoot({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'dashBoard',
                methodName: 'sifreDegis',
                pwd: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        sifreDegis({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'account',
                methodName: 'userList',
                userID: authData.userID,
                sifreDegis: authData.sifre,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        userRemove({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'account',
                methodName: 'userList',
                delUser: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        userUpdate({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'account',
                methodName: 'userList',
                level: authData.tur || ' ',
                isim: authData.adSoyad || ' ',
                tel: authData.telefon || ' ',
                ePosta: authData.ePosta || ' ',
                editUser: authData.userID || ' ',
                modul: authData.modul || ' ',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('USER_LIST_ACTION', res.userList)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        userSave({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'account',
                methodName: 'userList',
                addUser: 1,
                level: authData.tur || ' ',
                user: authData.kullaniciAdi || ' ',
                pwd: authData.sifre || ' ',
                isim: authData.adSoyad || ' ',
                tel: authData.telefon || ' ',
                ePosta: authData.ePosta || ' ',
                modul: authData.modul || ' ',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        userStatus({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'account',
                methodName: 'userList',
                userPasif: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('USER_LIST_ACTION', res.userList)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        userList({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'account',
                methodName: 'userList',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('USER_LIST_ACTION', res.userList)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
