import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Cleave from 'vue-cleave-component'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.use(Cleave)

// info input type
Vue.prototype.ibanType = {
    blocks: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
}

// info global değişkenler
Vue.prototype.priceStep = 0.0001
Vue.prototype.pdfQuality = 5 // todo Onerilen 5 tir. Gelen geridönüşlere göre pdf oluşturma yavaş çalışıyor ise bu sayısı düşürünüz.
