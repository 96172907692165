import store from '@/store'

export default {
    state: {
        havaleler: [],
    },
    getters: {
        HAVALE_GET(state) {
            if (state.havaleler) {
                return state.havaleler
            }
            return false
        },
    },
    mutations: {
        BANKA_ACTION(state, payload) {
            payload != 'remove' ? state.havaleler = { ...payload } : state.havaleler = []
        },
    },
    actions: {
        bankaHavale({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'banka',
                methodName: 'bankaHavaleleri',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const data = { ...res }
                        delete data.userToken
                        commit('BANKA_ACTION', data)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
