import store from '@/store'

export default {
    state: {
        aktarimDetay: [],
        depoList: [],
        depoDetay: {
            depoStokList: [],
            depoAktarim: [],
        },
    },
    getters: {
        AKTARIM_DETAY_GET(state) {
            if (state.aktarimDetay) {
                return {
                    bilgiler: state.aktarimDetay.bilgiler,
                    aktarilanStoklar: state.aktarimDetay.aktarilanStoklar,
                }
            }
            return false
        },
        DEPO_DETAY(state) {
            if (state.depoDetay) {
                return {
                    depoStokList: state.depoDetay.depoStokList,
                    depoAktarim: state.depoDetay.depoAktarim,
                }
            }
            return false
        },
        DEPO_LIST_GET(state) {
            if (state.depoList) {
                return state.depoList
            }
            return false
        },
    },
    mutations: {
        DEPO_DETAY_AKTARIM_DETAY_ACTION(state, payload) {
            const veri = { ...payload }
            payload != 'remove' ? state.aktarimDetay = veri : state.aktarimDetay = []
        },
        DEPO_DETAY_AKTARIM_ACTION(state, payload) {
            payload != 'remove' ? state.depoDetay.depoAktarim = { ...payload } : state.depoDetay.depoAktarim = []
        },
        DEPO_DETAY_STOK_ACTION(state, payload) {
            payload != 'remove' ? state.depoDetay.depoStokList = { ...payload } : state.depoDetay.depoStokList = []
        },
        DEPOLIST_ACTION(state, payload) {
            payload != 'remove' ? state.depoList = { ...payload } : state.depoList = []
        },
    },
    actions: {
        aktarimDetayIade({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'aktarimDetayIade',
                aktarimID: authData.aktarimID,
                aktarilanAdet: authData.aktarilanAdet || 0,
                detayID: authData.detayID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('DEPO_DETAY_AKTARIM_DETAY_ACTION', res)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        aktarimDetay({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'aktarimDetay',
                aktarimID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('DEPO_DETAY_AKTARIM_DETAY_ACTION', res)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoDetayAktarim({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'depoAktarimSearch',
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 40,
                searchKey: authData.searchKey,
                depoID: authData.depoID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('DEPO_DETAY_AKTARIM_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoDetayStok({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'depoStokListesiSearch',
                baslangic: authData.baslangic || 0,
                uzunluk: authData.uzunluk || 40,
                searchKey: authData.searchKey,
                depoID: authData.depoID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('DEPO_DETAY_STOK_ACTION', res.liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoUpdate({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'depoDuzenle',
                depoAdi: authData.depoAdi,
                depoID: authData.depoID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('DEPOLIST_ACTION', res.data)
                            commit('setToken', res.userToken)
                            resolve(res)
                        }
                        reject(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoRemove({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'depoSil',
                depoID: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('DEPOLIST_ACTION', res.data)
                            commit('setToken', res.userToken)
                            resolve(res)
                        }
                        reject(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoSave({ commit }, authData) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'depoKaydet',
                depoAdi: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('DEPOLIST_ACTION', res.data)
                            commit('setToken', res.userToken)
                            resolve(res)
                        }
                        reject(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        depoListesi({ commit }) {
            const data = {
                crm_token: store.getters.TOKEN_GET,
                serviceName: 'depo',
                methodName: 'depoListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        const liste = { ...res }
                        delete liste.userToken
                        commit('DEPOLIST_ACTION', liste)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
